import React from 'react'
import './SocialLinks.css'

const SocialLinks = () => {

  const SLList = [
    { id: 1 , smName: 'Instagram' , url: 'https://www.instagram.com.br/petaloo.oficial'},
    { id: 2 , smName: 'Facebook' , url: 'https://www.facebook.com.br/sejaumpetaloouco'},
    { id: 3 , smName: 'TikTok' , url: 'https://www.tiktok.com/@petaloo.oficial'},
    { id: 4 , smName: 'Loja - Parceiro Petz' , url: 'https://petalooja.parceiropetz.com.br/'}
  ]

  /** smName == Social Media Name */
  /** SLList = Social Links List */

  const mediaLinks = SLList.map((props) =>
    <div key={props.id} className='smLinks'>
      <a href={props.url} target='_blank' rel='noopener noreferrer'>{props.smName}</a>
    </div>
  )

  return (
    <>
      {mediaLinks}
    </>
  )
}

export default SocialLinks