import React from 'react'
import './Header.css'

const Header = () => {
  return (
    <div className='profileContent'>
        <div className='profileContent_Avatar'></div>
        <h3>@petaloo.oficial</h3>
        <p>Loucos por cães,<br/>apaixonados por pets.</p>
    </div>
  )
}

export default Header