import React from 'react'
import Header from './components/Header/Header'
import SocialLinks from './components/SocialLinks/SocialLinks'

const App = () => {
  return (
    <>
      <Header />
      <br/><br/><br/>
      <SocialLinks />
    </>
  )
}

export default App